/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-90deg-down': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M4.854 14.854a.5.5 0 01-.708 0l-4-4a.5.5 0 01.708-.708L4 13.293V3.5A2.5 2.5 0 016.5 1h8a.5.5 0 010 1h-8A1.5 1.5 0 005 3.5v9.793l3.146-3.147a.5.5 0 01.708.708z"/>',
    },
});
